//Created by Logan Cropper 6/5/24

import React, { Component } from "react";
import { Table } from "reactstrap";
import { GROUPS_URL } from "../../constants";
import ConfirmRemovalModal from "../ConfirmRemovalModal";
import NewGroupModal from "./NewGroupModal";

class GroupsList extends Component{
    render(){
        const{groups, searchQuery, resetState} = this.props;

        const filteredGroups = groups.filter((group) => {
            const nameFilter = group.name.toLowerCase().includes(searchQuery.toLowerCase()) || group.description.toLowerCase().includes(searchQuery.toLowerCase());

            return(nameFilter);
        })

        return (
            <Table light className="m-0 p-0">
                <thead>
                    <tr>
                        <th>Group Name</th>
                        <th>Description</th>
                        <th>Balance</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {!filteredGroups || filteredGroups.length <= 0 ? (
                        <tr>
                            <td colSpan="4" align="center">
                                <b>No matching groups found</b>
                            </td>
                        </tr>
                    ) : (
                        filteredGroups.map((group) => (
                            <tr key={group.pk}>
                                <td>{group.name}</td>
                                <td>{group.description}</td>
                                <td>{group.balance}</td>
                                <td align="right">
                                    <NewGroupModal
                                        create = {false}
                                        group = {group}
                                        resetState = {resetState}
                                    />
                                    &nbsp;&nbsp;
                                    <ConfirmRemovalModal
                                        pk = {group.pk}
                                        resetState = {resetState}
                                        apiUrl = {GROUPS_URL}
                                        itemName = {group.name}
                                    />
                                </td>
                            </tr>
                        )))
                    }
                </tbody>
            </Table>
        );
    }
}

export default GroupsList;