import React, { Fragment } from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer"

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    padding: 40,
  },
  header: {
    fontSize: 20,
    textAlign: "center",
  },
  date: {
    fontSize: 16,
    textAlign: "center",
  },
  purchase_info: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
  table_header: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    borderTopWidth: "2px",
    borderBottomWidth: "2px",
    marginTop: 20,
    marginBottom: 5,
  },
  table_footer: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    borderTopWidth: "2px",
    marginTop: 5,
  },
  table_row: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
  },
  table_column_1: {
    flexBasis: "10%",
  },
  table_column_2: {
    flexBasis: "40%",
    flexGrow: 1,
  },
  table_column_3: {
    flexBasis: "20%",
  },
  table_column_4: {
    flexBasis: "20%",
    flexGrow: 1,
  },
})

function ReceiptsPDF(props) {
  const a = 0
  return (
    <PDFViewer showToolbar={true} width={"100%"} height={600}>
      <Document title={"ELC_Receipt_" + props.purchase.dateOfPurchase}>
        <Page size={400} style={styles.page}>
          {/* Title header */}
          <View style={styles.header}>
            <Text>BYU ELC Checkout</Text>
          </View>
          {/* Date */}
          <View style={styles.date}>
            <Text>{props.purchase.dateOfPurchase}</Text>
          </View>
          {/* Purchaser info */}
          <View style={styles.purchase_info}>
            {/*CHANGE TO FETCH API NAME  */}
            <Text>Name: {props.purchase.purchaserName}</Text>
            <Text>ID: {props.purchase.purchaserID}</Text>
            <Text>
              {props.getGroupName(props.purchase) &&
                "Purchasing Group: " + props.getGroupName(props.purchase)}
            </Text>
          </View>
          {/* Item table header */}
          <View style={styles.table_header}>
            <Text style={styles.table_column_1}>Qty.</Text>
            <Text style={styles.table_column_2}>Item Name</Text>
            <Text style={styles.table_column_3}>Price</Text>
            <Text style={styles.table_column_4}>Subtotal</Text>
          </View>
          {/* Purchased items */}
          {props.purchase.OutgoingReceiptsExtendeds.map((item) => (
            <View style={styles.table_row}>
              <Text style={styles.table_column_1}>{item.quantity}</Text>
              <Text style={styles.table_column_2}>{item.name}</Text>
              <Text style={styles.table_column_3}>
                {parseFloat(item.price).toFixed(2)}
              </Text>
              <Text style={styles.table_column_4}>
                {parseFloat(item.quantity * item.price).toFixed(2)}
              </Text>
            </View>
          ))}
          ;{/* Price total footer */}
          <View style={styles.table_footer}>
            <Text style={styles.table_column_1}></Text>
            <Text style={styles.table_column_2}></Text>
            <Text style={styles.table_column_3}>Total:</Text>
            <Text style={styles.table_column_4}>
              {props.getPriceTotal(props.purchase).toFixed(2)}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default ReceiptsPDF
