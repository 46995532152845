import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import api from "../../api"
import { ACCESS_TOKEN, REFRESH_TOKEN, MAIN_CART_ID } from "../../constants"
import { CART_URL, USER_URL } from "../../constants"
import { AuthContext } from "../DAO/AuthContext"

import "./LoginForm.css"
import LoadingIndicator from "./LoadingIndicator"

function LoginForm({ route, method }) {
  //global variable
  const { login, currentUser, loginBypass } = useContext(AuthContext)
  //class variables
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [userLoaded, setUserLoaded] = useState(false)
  const navigate = useNavigate()

  const createCart = async (userId, username) => {
    try {
      const res2 = await api.get(process.env.REACT_APP_CART_URL)
      //console.log(res.data);
      console.log("success!")
    } catch (error) {
      alert(error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const success = await login(username, password)
      if (success) {
        // navigate("/") // Navigate to homepage on successful login
      } else {
        // Display error message
        alert("Login failed. Please check your credentials and try again.")
      }
    } catch (error) {
      console.log(error)
      alert("An unexpected error occurred. Please try again later.")
      // } finally
      setLoading(false)
    }

    // loginBypass()
    // setLoading(false)
    navigate("/")
  }

  useEffect(() => {
    if (currentUser !== undefined) {
      createCart(currentUser.id, currentUser.username)
      // console.log(currentUser)
      setUserLoaded(true)
    }
  }, [currentUser])

  useEffect(() => {
    if (!loading && userLoaded) {
      handleReturn()
    }
  }, [userLoaded])

  const handleReturn = () => {
    navigate("/")
  }

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <h1>Login</h1>
      <input
        className="form-input"
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <input
        className="form-input"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      {loading && <LoadingIndicator />}
      <button className="form-button" type="submit">
        Login
      </button>
      <button className="form-button" type="button" onClick={handleReturn}>
        Back
      </button>
    </form>
  )
}

export default LoginForm
