//Created by Logan Cropper 6/6/2024

import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Label, Alert } from "reactstrap";
//import axios from "axios";
import api from "../../api";
import { GROUPS_URL } from "../../constants";

class NewGroupForm extends Component {
  state = {
    pk: 0,
    name: "",
    description: "",
    balance: 0,
    operatingUnit: "",
    accountCode: "",
    classCode: "",
    department: "",
    controllerEmail: "",
    errorMessage: "",
  };

  componentDidMount() {
    if (this.props.group) {
      const {
        pk,
        name,
        description,
        balance,
        operatingUnit,
        accountCode,
        classCode,
        department,
        controllerEmail,
      } = this.props.group;
      this.setState({
        pk: pk,
        name: name,
        description: description,
        balance: balance,
        operatingUnit: operatingUnit,
        accountCode: accountCode,
        classCode: classCode,
        department: department,
        controllerEmail: controllerEmail,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkLengths = () => {
    if (this.state.operatingUnit.length > 8) {
      this.setState({
        errorMessage: "Operating Unit # cannot be longer than 8 characters",
      });
      return false;
    }
    if (this.state.accountCode.length > 4) {
      this.setState({
        errorMessage: "Account Code cannot be longer than 4 digits",
      });
      return false;
    }
    return true;
  };

  createItem = (e) => {
    //TODO: ADD FIELD LENGTH CHECKING. AXIOS HAS IT BUILT IN BUT THE ERROR MESSAGE IS OPAQUE
    e.preventDefault();
    if (
      !this.state.name ||
      !this.state.description ||
      !this.state.operatingUnit ||
      !this.state.accountCode ||
      !this.state.classCode ||
      !this.state.department ||
      !this.state.controllerEmail
    ) {
      this.setState({ errorMessage: "Please fill out all fields." });
      return;
    }

    if (!this.checkLengths()) {
      return;
    }

    const formData = new FormData();
    Object.keys(this.state).forEach((key) => {
      if (this.state[key] !== null) {
        formData.append(key, this.state[key]);
      }
    });

    api
      .post(GROUPS_URL, formData)
      .then(() => {
        this.props.resetState();
        this.props.toggle();
      })
      .catch((error) => {
        console.error("Error creating group: ", error);
        this.setState({
          errorMessage: "Error creating group. Please try again.",
        });
      });
  };

  editItem = (e) => {
    e.preventDefault();
    if (
      !this.state.name ||
      !this.state.description ||
      !this.state.balance ||
      !this.state.operatingUnit ||
      !this.state.accountCode ||
      !this.state.classCode ||
      !this.state.department ||
      !this.state.controllerEmail
    ) {
      this.setState({ errorMessage: "Please fill out all fields." });
      return;
    }

    const formData = new FormData();
    Object.keys(this.state).forEach((key) => {
      if (this.state[key] !== null) {
        formData.append(key, this.state[key]);
      }
    });

    api
      .post(GROUPS_URL + this.state.pk, formData)
      .then(() => {
        this.props.resetState();
        this.props.toggle();
      })
      .catch((error) => {
        console.error("Error editing group: ", error);
        this.setState({
          errorMessage: "Error editing group. Please try again.",
        });
      });
  };

  defaultIfEmpty = (value) => {
    return value === "" ? "" : value;
  };

  render() {
    return (
      <Form onSubmit={this.props.group ? this.editItem : this.createItem}>
        {this.state.errorMessage && (
          <Alert color="danger">{this.state.errorMessage}</Alert>
        )}
        {/* Form groups for each attribute */}
        <FormGroup>
          <Label for="name">Group Name:</Label>
          <Input
            type="text"
            name="name"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.name)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="description">Description:</Label>
          <Input
            type="text"
            name="description"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.description)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="operatingUnit">Operating Unit #:</Label>
          <Input
            type="text"
            name="operatingUnit"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.operatingUnit)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="accountCode">Account Code:</Label>
          <Input
            type="text"
            name="accountCode"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.accountCode)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="classCode">Class Code:</Label>
          <Input
            type="text"
            name="classCode"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.classCode)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="department">Department:</Label>
          <Input
            type="text"
            name="department"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.department)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="controllerEmail">Controller Email:</Label>
          <Input
            type="text"
            name="controllerEmail"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.controllerEmail)}
          />
        </FormGroup>
        <Button>Send</Button>
      </Form>
    );
  }
}

export default NewGroupForm;
