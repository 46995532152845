import axios from "axios"

export const fetchCsrfToken = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_CSRF_TOKEN)
    return response.data.csrfToken
  } catch (error) {
    return null
  }
}
