import React, { useState } from "react"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import Datetime from "react-datetime"
import moment from "moment"
import "react-datetime/css/react-datetime.css"

import api from "../../api"
import { LOANCART_ITEM_URL } from "../../constants"

const LoanForm = ({ item, isEquipment, resetState, toggle }) => {
  const [quantityToOrder, setQuantityToOrder] = useState(1)
  const [startDate, setStartDate] = useState(new Date())

  const handleQuantityChange = (e) => {
    setQuantityToOrder(e.target.value)
  }

  const handleDateChange = (date) => {
    if (moment(date).isValid()) {
      setStartDate(date) // Keep startDate as a Date object
    } else {
      console.error("Invalid date:", date)
    }
  }

  const createLoanItem = async (e) => {
    e.preventDefault()

    const data = {
      loanCart: 1,
      item: isEquipment ? null : item.pk,
      equipment: isEquipment ? item.pk : null,
      returnDate: moment(startDate).format("YYYY-MM-DD"),
      quantity: quantityToOrder,
      isEquipment: isEquipment,
    }

    console.log(data)

    try {
      await api.post(LOANCART_ITEM_URL, data)
      resetState()
      toggle()
    } catch (error) {
      console.error("Error creating loan item:", error)
    }
  }

  return (
    <div>
      <Form onSubmit={createLoanItem}>
        <FormGroup>
          <Label for="quantityToOrder">Quantity:</Label>
          <Input
            type="number"
            name="quantityToOrder"
            onChange={handleQuantityChange}
            value={quantityToOrder}
          />
        </FormGroup>

        <FormGroup>
          <Label for="datePicker">Date:</Label>
          <Datetime value={startDate} onChange={handleDateChange} />
        </FormGroup>

        <Button type="submit">Submit</Button>
      </Form>
    </div>
  )
}

export default LoanForm
