import React, { useContext, useEffect, useState } from "react"
import "./Header.css"
import { AuthContext } from "../DAO/AuthContext"
import { CART_URL, MAIN_CART_ID } from "../../constants"

import { useNavigate } from "react-router-dom"

import api from "../../api"
import { Button } from "reactstrap"

const Header = () => {
  const { logout, isAuthenticated } = useContext(AuthContext)
  const [loggedin, setLoggedin] = useState(isAuthenticated)

  //updates loggedin variable when AuthContext is updated
  useEffect(() => {
    setLoggedin(isAuthenticated)
  }, [isAuthenticated])

  const navigate = useNavigate()

  const handleLogin = () => {
    navigate("/login")
  }

  const handleLogout = async () => {
    //destroy temporary cart
    // const curr_cart = localStorage.getItem(MAIN_CART_ID)
    // try {
    //   const res = await api.delete(`${CART_URL}${curr_cart}`)
    //   console.log(res.data)
    // } catch (error) {
    //   alert(error)
    // }
    //remove authorization to admin tabs
    logout()
    //refresh page / return to home tab
    // window.location.href = "/"
  }

  const redirectToHome = () => {
    window.location.href = "/"
  }

  //render() {
  return (
    <header className="byu-header">
      <div className="byu-official">
        <div className="byu-logo">
          {/* This is the image on the left of the header */}
          <img
            src={`${process.env.PUBLIC_URL}/static/images/BYU_LOGO.png`}
            alt="BYU Logo"
            onClick={redirectToHome}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="byu-separator"></div> {/* White separator bar */}
        <div className="byu-title">
          {/* This is the title text */}
          <h2>ECE Shop</h2>
        </div>
      </div>
      <div className="login-logout-button">
        {loggedin ? (
          <Button onClick={handleLogout} color="light">
            Logout
          </Button>
        ) : (
          <Button onClick={handleLogin} color="light">
            Login
          </Button>
        )}
      </div>
    </header>
  )
}

export default Header
