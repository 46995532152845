// 7/26/24 - Modernized - ChatGPT
// Created by: Anthony Cardenas; Date: May 30, 2024
// Modernize to current React Version 7/30/24

import React, { useState, Fragment } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import EquipmentForm from "./EquipmentForm"

const EquipmentModal = ({ create, resetState, equipment }) => {
  const [modal, setModal] = useState(false)

  // Change whether the frame is viewable or not
  const toggle = () => {
    setModal(!modal)
  }

  const title = create ? "Creating New Equipment" : "Editing Equipment"
  const button = create ? (
    <Button
      color="primary"
      className="float-right"
      onClick={toggle}
      style={{ minWidth: "200px" }}
    >
      Create New Equipment
    </Button>
  ) : (
    <Button onClick={toggle}>Edit</Button>
  )

  return (
    <Fragment>
      {button}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <EquipmentForm
            resetState={resetState}
            toggle={toggle}
            equipment={equipment}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default EquipmentModal
