import React, { Fragment } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';


// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        padding:50
    },
    header: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 10
    },
    date: {
        fontSize: 14,
        textAlign: 'center',
    },
    group_name: {
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 'bold',
        marginTop: 20,
        borderBottomWidth: '2px'
    },
    item_row: {
        fontSize: 10,
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'row'

    },
    item_row_header: {
        fontSize: 12,
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'row',
        
    },
    item_column_1: {
        flexBasis: '30%',
        flexGrow: 1
    },
    item_column_2: {
        flexBasis: '20%'
    },
    item_column_3: {
        flexBasis: '20%'
    },
    item_column_4: {
        flexBasis: '10%',
        textAlign: 'right'
    },

});

  // Create Document Component
function ReceiptReport(props) {
    //Sort receipts by group name
    const sortedReceipts = props.receipts.sort((a,b) => (props.getGroupName(a) > props.getGroupName(b)) ? 1 : ((props.getGroupName(b) > props.getGroupName(a)) ? -1 : 0));
    var groupTotal = 0;

    //Get list of groups in report
    var groupsList = [];
    sortedReceipts.map(receipt => {
        var groupName = props.getGroupName(receipt);
        if(!groupsList.find(item => item === groupName)){
            groupsList.push(groupName);
        }
    });

    return(
        <PDFViewer
            showToolbar={true}
            width={'100%'}
            height={800}
        >
            <Document title={"ELCpurchases_" + props.startDate + "_" + props.endDate}>
                <Page size="letter" style={styles.page}>
                    {/* Title header */}
                    <View style={styles.header}>
                        <Text>ELC GROUP PURCHASES REPORT</Text>
                    </View>

                    {/* Date range */}
                    <View style={styles.date}>
                        <Text>{props.startDate ? "From   " + props.startDate : ""}      {props.endDate ? "To   " + props.endDate : ""}</Text>
                    </View>

                    {/* Sections for each group */}
                    {groupsList.map((group) => {
                        groupTotal = 0;

                        return(
                            <Fragment>
                                <View style={styles.group_name}>
                                    <Text>Group Name: {group} ({props.getChartField(group)})</Text>
                                </View>

                                {/* Table Headers */}
                                <View style={styles.item_row_header}>
                                    <Text style={styles.item_column_1}>Purchaser</Text>
                                    <Text style={styles.item_column_2}>Item Qty.</Text>
                                    <Text style={styles.item_column_3}>Date</Text>
                                    <Text style={styles.item_column_4}>Subtotal</Text>
                                </View>

                                {/* Purchases in table */}
                                {props.receipts.filter(receipt => (props.getGroupName(receipt) === group)).map((item) => {
                                    groupTotal += props.getPriceTotal(item);

                                    return(
                                        <Fragment>
                                            <View style={styles.item_row}>
                                                <Text style={styles.item_column_1}>{item.purchaserName}</Text>
                                                <Text style={styles.item_column_2}>{props.getNumItems(item)}</Text>
                                                <Text style={styles.item_column_3}>{item.dateOfPurchase}</Text>
                                                <Text style={styles.item_column_4}>{props.getPriceTotal(item).toFixed(2)}</Text>
                                            </View>
                                            
                                        </Fragment>
                                        
                                    )
                                })}

                                {/* Group price total */}
                                <View style={styles.item_row_header}>
                                    <Text style={styles.item_column_1}></Text>
                                    <Text style={styles.item_column_2}></Text>
                                    <Text style={styles.item_column_3}>Total:</Text>
                                    <Text style={styles.item_column_4}>{groupTotal.toFixed(2)}</Text>
                                </View>
                            </Fragment>
                        )
                    })}
                </Page>
            </Document>
        </PDFViewer>
    );
};

  export default ReceiptReport;