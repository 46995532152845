//Created by Logan Cropper 8/9/24

import React, { useState } from "react"
import { Container } from "reactstrap"
import {Tab, Tabs, TabList, TabPanel} from "react-tabs"

import ".././Body.css"

import ProjectPanel from "./ProjectPanel"

const ProjectsTab = () => {
    const [selectedTab, setSelectedTab] = useState(0)

    const handleTabChange = (index) => {
        setSelectedTab(index)
    }

    return (
        <Container className="main-container" fluid="true">
            <Tabs
                selectedIndex={selectedTab}
                onSelect={handleTabChange}
            >
                <div className="title-text">
                    <h2>Project Requests</h2>
                </div>
                <TabList>
                    <Tab>3D Print</Tab>
                    <Tab>PCB Mill</Tab>
                    <Tab>Laser Cut</Tab>
                </TabList>
                <TabPanel>
                    <ProjectPanel type="TDP"/>
                </TabPanel>
                <TabPanel>
                    <ProjectPanel type="PCB"/>
                </TabPanel>
                <TabPanel>
                    <ProjectPanel type="LC"/>
                </TabPanel>
            </Tabs>
        </Container>
    )

}

export default ProjectsTab

