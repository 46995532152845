//Edited 5/29/24 - Logan Cropper

import React, { Component, Fragment } from "react"
import { Table } from "reactstrap"
import ReceiptsModal from "./ReceiptsModal"
import ReceiptReportModal from "./ReceiptReportModal"
//import axios from "axios";
import api from "../../api"
import { GROUPS_URL } from "../../constants"

const PLACEHOLDER_PURCHSE_ID = 107

class OutGoingReceiptsList extends Component {
  state = {
    groups: [],
  }

  componentDidMount() {
    this.loadGroups()
  }

  // Get total number of items in the order
  getNumItems = (receipt) => {
    var itemCnt = 0
    for (var i = 0; i < receipt.OutgoingReceiptsExtendeds.length; i++) {
      itemCnt += receipt.OutgoingReceiptsExtendeds[i].quantity
    }
    return itemCnt
  }

  // Get total price of the order
  getPriceTotal = (receipt) => {
    var totalPrice = 0
    for (var i = 0; i < receipt.OutgoingReceiptsExtendeds.length; i++) {
      totalPrice +=
        receipt.OutgoingReceiptsExtendeds[i].price *
        receipt.OutgoingReceiptsExtendeds[i].quantity
    }
    return totalPrice
  }

  // Get group name
  getGroupName = (receipt) => {
    return this.state.groups.find(
      (group) => group.pk === receipt.groupPurchaseID
    )
      ? this.state.groups.find((group) => group.pk === receipt.groupPurchaseID)
          .name
      : ""
  }

  getChartField = (groupName) => {
    return this.state.groups.find((group) => group.name === groupName)
      ? this.state.groups.find((group) => group.name === groupName).chartField
      : ""
  }

  loadGroups = () => {
    api.get(GROUPS_URL).then((res) => this.setState({ groups: res.data }))
  }

  render() {
    const { receipts, searchQuery, groupFilter, startDate, endDate } =
      this.props
    const startDateItem = new Date(startDate)
    const endDateItem = new Date(endDate)

    // Filter the items based on the search terms
    const filteredItems = receipts.filter((receipt) => {
      //Referencing item attribute of the items app
      const userID = receipt.purchaserID.toString()
      const groupName = this.state.groups.find(
        (group) => group.pk === receipt.groupPurchaseID
      )
        ? this.state.groups.find(
            (group) => group.pk === receipt.groupPurchaseID
          ).name
        : ""
      const date = new Date(receipt.dateOfPurchase)
      const itemFilter = userID
        ? userID.toLowerCase().includes(searchQuery.toLowerCase())
        : false
      const groupNameFilter = groupName
        ? groupName.toLowerCase().includes(searchQuery.toLowerCase())
        : false
      const startDateFilter =
        startDateItem != "Invalid Date" ? date >= startDateItem : true
      const endDateFilter =
        endDateItem != "Invalid Date" ? date <= endDateItem : true
      const groupsOnlyFilter = groupFilter ? (this.getGroupName(receipt) != "") : true
      const defaultFilter = receipt.pk != PLACEHOLDER_PURCHSE_ID
      return (
        (itemFilter || groupNameFilter) &&
        startDateFilter &&
        endDateFilter &&
        groupsOnlyFilter &&
        defaultFilter
      )
    })
    return (
      <Fragment>
        {groupFilter && (
          <ReceiptReportModal
            receipts={filteredItems}
            startDate={startDate}
            endDate={endDate}
            getGroupName={this.getGroupName}
            getChartField={this.getChartField}
            getPriceTotal={this.getPriceTotal}
            getNumItems={this.getNumItems}
          />
        )}
        <Table light style={{ margin: "0", padding: "0" }}>
          {" "}
          {/* Look up ReactStrap Table for more detailed information */}
          <thead>
            {" "}
            {/* The head includes the descriptions for the attributes */}
            <tr>
              <th>Purchase ID</th>
              <th>Purchaser</th>
              <th>BYU ID</th>
              <th>Group</th>
              <th>Total</th>
              <th>Purchase Date</th>
              {/* //Possibly implemented as some future date
                            <th>ICN</th>
                            <th>Loaned to</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {" "}
            {/* The body includes the values for the items */}
            {/* Check to see if there are valid items before displaying them */}
            {!filteredItems || filteredItems.length <= 0 ? (
              <tr>
                <td colSpan="8" align="center">
                  <b>No matching items found</b>
                </td>
              </tr>
            ) : (
              filteredItems.map((purchase) => (
                <tr key={purchase.pk}>
                  <td>{purchase.pk}</td>
                  <td>{purchase.purchaserName}</td>
                  <td>{purchase.purchaserID}</td>
                  <td>{this.getGroupName(purchase)}</td>
                  <td>${this.getPriceTotal(purchase).toFixed(2)}</td>
                  <td>{purchase.dateOfPurchase}</td>
                  <ReceiptsModal //Receipt view button
                    purchase={purchase}
                    showButton={true}
                    getGroupName={this.getGroupName}
                  />
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Fragment>
    )
  }
}

export default OutGoingReceiptsList
