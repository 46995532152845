import React, { Fragment, useContext } from "react"
import TabBody from "./TabBody"
import Header from "./Header/Header"

function Home() {
  return (
    <Fragment>
      <Header />
      <TabBody />
    </Fragment>
  )
}

export default Home
