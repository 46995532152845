// Created 7/15/24 - Logan Cropper

import React, { useState } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import GroupSelectForm from "./GroupSelectForm"

const GroupSelectModal = ({ setGroup }) => {
  const [modal, setModal] = useState(false)

  // Toggle modal visibility
  const toggle = () => {
    setModal(!modal)
  }

  return (
    <>
      <Button
        color="primary"
        outline
        className="mt-4 mb-4 me-2 float-left"
        onClick={toggle}
        style={{ minWidth: "80px" }}
      >
        Select Group
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Select Group</ModalHeader>
        <ModalBody>
          <GroupSelectForm toggle={toggle} setGroup={setGroup} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default GroupSelectModal
