import React from "react"

import { createColumnHelper } from "@tanstack/react-table"

import RemoveCartItemModal from "./RemoveCartItemModal"
import Table from "../Table/Table"

const ItemCart = ({ data, resetState, totalPrice }) => {
  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("price", {
      cell: (info) => info.getValue(),
      header: () => <span>Price</span>,
      footer: () => <span>Total: ${totalPrice}</span>, // Add footer to display total price
    }),
    columnHelper.accessor("quantity", {
      cell: (info) => info.getValue(),
      header: () => <span>Quantity</span>,
    }),
    columnHelper.accessor("pk", {
      cell: ({ row }) => {
        return (
          <RemoveCartItemModal
            item={row.original}
            resetState={resetState}
            isLoan={false}
          />
        )
      },
      header: () => <span></span>,
    }),
  ]
  return (
    <>
      <div className="title-text">
        <h3>Items Cart</h3>
      </div>
      <Table
        parentData={data}
        columns={columns}
        paginate={false}
        searchable={false}
      />
    </>
  )
}

export default ItemCart
