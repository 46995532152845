import React from "react"

import { Button, Col, Row } from "reactstrap"

import SavedCartModal from "./SavedCartModal"
import CheckoutModal from "./CheckoutModal"
import MiscModal from "./MiscModal"

const CartHeader = ({
  setLoadedCart,
  resetState,
  totalPrice,
  totalLoans,
  cartID,
  loanCartID,
  group,
}) => {
  // const addMisc = async (e) => {
  //   // e.preventDefault() // Prevent form submission
  //   const curr_cart = localStorage.getItem(MAIN_CART_ID)

  //   const payLoad = {
  //     cart: curr_cart,
  //     miscName: "Test Item",
  //     miscPrice: 10.0,
  //     quantity: 1,
  //   }

  //   console.log("hi")

  //   try {
  //     const response = await api.post(CART_ITEM_URL, payLoad)
  //     console.log(response)
  //   } catch (error) {
  //     console.error("Error during test request:", error)
  //   }
  // }

  return (
    <>
      <div className="title-text">
        <h2>Cart</h2>
      </div>
      <Row className="mt-3 mb-3">
        <Col className="d-flex justify-content-start">
          <MiscModal resetState={resetState} />
        </Col>
        <Col className="d-flex justify-content-end">
          {/* <SavedCartModal
            setLoadedCart={setLoadedCart}
            resetState={resetState}
          /> */}
          <CheckoutModal
            resetState={resetState}
            totalPrice={totalPrice}
            totalLoans={totalLoans}
            cartID={cartID}
            loanCartID={loanCartID}
            group={group}
            transactionType={"refund"}
          />
          <CheckoutModal
            resetState={resetState}
            totalPrice={totalPrice}
            totalLoans={totalLoans}
            cartID={cartID}
            loanCartID={loanCartID}
            group={group}
            transactionType={"charge"}
          />
        </Col>
      </Row>
    </>
  )
}

export default CartHeader
