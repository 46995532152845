import React, { useState } from "react"

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import axios from "axios"

const MoveToCancelledModal = ({ project, resetState, projectType }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const cancelProject = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_PROJECT_URL}${projectType}/update_job/${project.pk}`,
        { status_type: "CN" }, // Body of the request
        {
          headers: {
            "Content-Type": "application/json", // Ensure you're sending JSON data
          },
        }
      )
      toggle() // Close the modal
      resetState() // Refresh the list of items
    } catch (error) {
      console.error("Error canelling project:", error)
    }
  }

  return (
    <div>
      <Button onClick={toggle} className="m-4" color="danger">
        Cancel
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Cancel project for {project.customerName}
        </ModalHeader>

        <ModalBody>Are you sure you want to cancel this project?</ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={cancelProject}>
            Cancel Project
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default MoveToCancelledModal
