import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./components/Home"
import Login from "./components/Header/Login"
import { AuthProvider } from "./components/DAO/AuthContext"
import CartTab from "./components/Cart/CartTab"

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          {/* Add a catch-all route to handle undefined paths */}
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </AuthProvider>
  )
}

export default App
