import axios from "axios"

import { fetchCsrfToken } from "./components/DAO/csrfTokenUtils"

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // Automatically send cookies (including JWT and CSRF tokens)
})

// Request interceptor for CSRF and JWT tokens
api.interceptors.request.use(
  async (config) => {
    const csrfToken = await fetchCsrfToken()

    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api

// import axios from "axios"

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL,
//   // withCredentials: true, // Automatically send cookies
// })

// // Now, `api` will behave just like axios, without any interceptors.
// // You can add any additional configurations here if needed, but for now,
// // it will just replicate Axios's default behavior.

// export default api
