// 3/6/24 - Modified - Tyler Perkins
// 5/20/24 - Modified - Logan Cropper

import React, { Component, Fragment } from "react"
import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap"

import axios from "axios"

import api from "../api"

class ConfirmRemovalModal extends Component {
  state = {
    modal: false,
  }

  //Change whether the frame is viewable or not
  toggle = () => {
    this.setState((previous) => ({
      modal: !previous.modal,
    }))
  }

  deleteItem = (pk) => {
    //The modelName parameter will determine where we are
    //deleting the pk from.

    let apiUrl = this.props.subModelType
      ? this.props.apiUrl + pk + "/" + this.props.subModelType
      : this.props.apiUrl + pk

    axios.delete(apiUrl).then(() => {
      this.props.resetState()
      this.toggle()
    })
  }

  render() {
    return (
      <Fragment>
        <Button color="danger" onClick={() => this.toggle()}>
          Remove
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Do you really want to delete this item?
          </ModalHeader>

          <ModalBody>
            <p>{this.props.itemName}</p>
            <p>ID: {JSON.stringify(this.props.pk)}</p>
          </ModalBody>

          <ModalFooter>
            <Button type="button" onClick={() => this.toggle()}>
              Cancel
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={() => this.deleteItem(this.props.pk)}
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    )
  }
}

export default ConfirmRemovalModal
