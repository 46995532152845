//Created by Logan Cropper 6/5/24

import React, {Component} from "react";
import {Col, Container, Row, Input} from "reactstrap";
//import axios from "axios";
import api from "../../api";
import { GROUPS_URL } from "../../constants";
import GroupsList from "./GroupsList";
import NewGroupModal from "./NewGroupModal";
import "./Groups.css"

class GroupsTab extends Component{
    constructor(props){
        super(props);
        this.state = {
            groups: [],
            searchQuery: "",
        };
    }

    componentDidMount(){
        this.resetState();
    }

    resetState = () => {
        this.getItems();
    }

    getItems = () => {
        api.get(GROUPS_URL).then((res => this.setState({groups: res.data})));
    }

    handleSearch = (searchQuery) => {
        this.setState({
            searchQuery:searchQuery
        })
    }

    render() {
        return (
            <Container className="main-container" fluid="true">
                <div className="title-text">
                    <h2>Groups</h2>
                </div>
                {/* Search bar, takes input and filters door codes accordingly */}
                <Row>
                    <Col>
                        <Input
                            className="mb-4 mt-4 w-50"
                            type="text"
                            placeholder="Search by group name"
                            value={this.state.searchQuery}
                            onChange={(e) => this.handleSearch(e.target.value)}
                        />
                    </Col>
                    <Col align="right">
                        <NewGroupModal
                            create = {true}
                            resetState = {this.resetState}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GroupsList
                            groups={this.state.groups}
                            resetState={this.resetState}
                            searchQuery={this.state.searchQuery}
                            handleSearch={this.handleSearch}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default GroupsTab;