import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
} from "reactstrap"
import api from "../../api"
import axios from "axios"

const StartJobModal = ({ typeOfRequest, project, resetState, projectType }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const startJob = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_PROJECT_URL}${projectType}/update_job/${project.pk}`,
        { status_type: "IP" }
      )
      toggle() // Toggle visibility, e.g., close a modal
      resetState() // Reset state, e.g., refresh a list of items
    } catch (error) {
      console.error("Error starting job:", error)
      // Optionally, show a user-friendly error message or notification
    }
  }

  return (
    <>
      <Button onClick={toggle} className="m-4" color="warning">
        Start Job
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Row>
            {typeOfRequest} Request: {project.customerName}
          </Row>
          <Row>
            <p>
              <a href={`mailto:${project.customerEmail}`}>
                {project.customerEmail}
              </a>
            </p>
          </Row>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col>Project File</Col>
            <Col>
              <Button href={project.file} download>
                Download
              </Button>
            </Col>
          </Row>
          <Row></Row>
        </ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={startJob}>
            Start Job
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Do you really want to delete this item?
        </ModalHeader>

        <ModalBody>Test</ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Cancel
          </Button>
          <Button type="button" color="primary">
            Yes
          </Button>
        </ModalFooter>
      </Modal> */}
    </>
  )
}

export default StartJobModal
