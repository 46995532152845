import React, { useState } from "react"
import {
  Button,
  Modal,
  Form,
  Label,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
} from "reactstrap"

import api from "../../api"

import { MAIN_CART_ID, CART_ITEM_URL } from "../../constants"

const MiscModal = ({ resetState }) => {
  const [modal, setModal] = useState(false)
  const [quantity, setQuantity] = useState()
  const [name, setName] = useState("")
  const [price, setPrice] = useState()

  const onNameChange = (e) => {
    setName(e.target.value)
  }

  const onPriceChange = (e) => {
    setPrice(e.target.value)
  }

  const onQuantityChange = (e) => {
    setQuantity(e.target.value)
  }

  const toggle = () => {
    setModal(!modal)
    setQuantity(null)
    setName("")
    setPrice(null)
    resetState()
  }

  const createMiscItem = async (e) => {
    const curr_cart = localStorage.getItem(MAIN_CART_ID)

    const payLoad = {
      cart: curr_cart, //Now uses the user who is making the request so this doesn't do anything
      miscName: name,
      miscPrice: price,
      quantity: quantity,
    }

    try {
      const response = await api.post(CART_ITEM_URL, payLoad)
      console.log(response)
    } catch (error) {
      console.error("Error during test request:", error)
    }
  }

  return (
    <>
      <Button
        onClick={toggle}
        style={{ minWidth: "80px" }}
        className="m-1 mb-4 mt-4 float-end"
      >
        Misc Purchase
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Miscellaneous Purchase</ModalHeader>
        <ModalBody>
          <Form onSubmit={() => console.log("kill yourself")}>
            <FormGroup>
              <Label for="name">Item Name</Label>
              <Input
                type="text"
                name="name"
                onChange={onNameChange}
                value={name}
              />
              <Label for="price">Price</Label>
              <Input
                type="number"
                name="price"
                onChange={onPriceChange}
                value={price}
              />
              <Label for="quantity">Quantity</Label>
              <Input
                type="number"
                name="quantity"
                onChange={onQuantityChange}
                value={quantity}
              />
            </FormGroup>

            <Button
              onClick={() => {
                toggle()
                createMiscItem()
                resetState()
              }}
            >
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default MiscModal
