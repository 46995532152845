// Created 7/26/24 - Logan Cropper

import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ReceiptReport from "./ReceiptReport";

class ReceiptReportModal extends Component {
    state = {
        modal:false
    };

    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    render() {
        return(
            <Fragment>
                <Button
                    color="primary"
                    onClick={this.toggle}
                >
                    Generate Report
                </Button>
                <Modal
                    isOpen={this.state.modal}   
                    toggle={this.toggle}
                    size={"xl"}
                >
                    <ModalHeader toggle={this.toggle}>
                        Purchase Report
                    </ModalHeader>
                    <ModalBody>
                        <ReceiptReport
                            receipts={this.props.receipts}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            getGroupName={this.props.getGroupName}
                            getChartField={this.props.getChartField}
                            getPriceTotal={this.props.getPriceTotal}
                            getNumItems={this.props.getNumItems}
                        />
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default ReceiptReportModal;