import React, { useState } from "react"

import { Modal, ModalHeader, Button, ModalFooter, ModalBody } from "reactstrap"

import { CART_ITEM_URL, LOANCART_ITEM_URL } from "../../constants"

//import axios from "axios"
import api from "../../api"

const RemoveCartItemModal = ({ item, resetState, isLoan }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const deleteItem = async () => {
    try {
      await api.delete(`${CART_ITEM_URL}${item.pk}`)
      //   resetState() // Reset local state or update UI as needed
      toggle() // Toggle visibility, e.g., close a modal
      resetState()
      console.log("Item deleted successfully")
    } catch (error) {
      console.error("Error deleting item:", error)
      // Optionally, show a user-friendly error message or notification
    }
  }

  const deleteLoanItem = async () => {
    try {
      await api.delete(`${LOANCART_ITEM_URL}${item.pk}`)
      //   resetState() // Reset local state or update UI as needed
      toggle() // Toggle visibility, e.g., close a modal
      resetState()
      console.log("Item deleted successfully")
    } catch (error) {
      console.error("Error deleting item:", error)
      // Optionally, show a user-friendly error message or notification
    }
  }

  return (
    <>
      <Button
        color="danger"
        onClick={toggle}
        style={{
          padding: "5px", // Reduce padding to make items smaller
          border: "1px solid #ccc",
          margin: "0 5px",
        }}
      >
        Remove
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Do you really want to delete this item?
        </ModalHeader>

        <ModalBody>Test</ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Cancel
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={isLoan ? deleteLoanItem : deleteItem}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RemoveCartItemModal
