// Created 7/15/24 - Logan Cropper

import React, { useState, useEffect } from "react"
import { Input, Table } from "reactstrap"
import api from "../../api"
import { GROUPS_URL } from "../../constants"

const GroupSelectForm = ({ setGroup, toggle }) => {
  const [groups, setGroups] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    loadGroups()
  }, [])

  const loadGroups = async () => {
    try {
      const response = await api.get(GROUPS_URL)
      setGroups(response.data)
    } catch (error) {
      console.error("Failed to load groups:", error)
    }
  }

  const handleSearch = (query) => {
    setSearchQuery(query)
  }

  const returnGroup = (group) => {
    setGroup(group)
    toggle()
  }

  const filteredGroups = groups.filter((group) =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  return (
    <Table light style={{ margin: "0", padding: "0" }}>
      <thead className="table-secondary">
        <tr>
          <th>
            <Input
              type="text"
              placeholder="Search Groups"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {filteredGroups.length === 0 ? (
          <tr>
            <td align="center">
              <b>No Matching Groups Found</b>
            </td>
          </tr>
        ) : (
          filteredGroups.map((group) => (
            <tr
              key={group.tk}
              data-item={group}
              onClick={() => returnGroup(group)}
              style={{ cursor: "pointer" }}
            >
              <td>{group.name}</td>
              <td>{group.chartField}</td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  )
}

export default GroupSelectForm
