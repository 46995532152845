import React, { Component } from "react";
import { Col, Container, Row, Input, Form, FormGroup, Label } from "reactstrap";
import ReceiptsList from "./ReceiptsList";

//import axios from "axios";
import api from "../../api";
import { RECEIPTS_URL } from "../../constants";

class ReceiptsTab extends Component {
  state = {
    receipts: [],
    searchQuery: "", // New state for the search query
    groupFilter: false,
    startDate: "",
  };

  componentDidMount() {
    this.resetState();
  }

  getReceipts = () => {
    // Get items with the API
    api.get(RECEIPTS_URL).then((res) => this.setState({ receipts: res.data }));
  };

  resetState = () => {
    // Reset the state
    this.getReceipts();
  };

  handleSearch = (searchQuery) => {
    // Search based on the searchQuery
    this.setState({ searchQuery });
  };

  render() {
    return (
      <Container className="main-container" fluid="true">
        {" "}
        {/* TODO: Make a CSS file with styling */}
        <div className="title-text">
          <h2>Shop Outgoing Receipts</h2>
        </div>
        <br />
        {/* Search bar, takes an input and filters the items accordingly */}
        <Form>
          <Row className="row-cols-lg-auto g-3 align-items-center">
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Search by userID or group"
                  value={this.state.searchQuery}
                  onChange={(e) => this.setState({searchQuery: e.target.value})}
                />
              </FormGroup>
            </Col>
            <Col>
              <Label>Date Range:</Label>
            </Col>
            <Col>
              <FormGroup>
                <Input
                  type="date"
                  value={this.state.startDate}
                  onChange={(e) => this.setState({startDate: e.target.value})}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup inline>
                <Input
                  type="date"
                  value={this.state.endDate}
                  onChange={(e) => this.setState({endDate: e.target.value})}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup switch inline>
                <Label check>Group Purchases Only</Label>
                <Input
                  type="switch"
                  role="switch"
                  checked={this.state.groupFilter}
                  onChange={() => {this.setState({groupFilter: !this.state.groupFilter})}}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <br /> {/* Two breaks is my lazy way of making it look nicer. Should be fixed at some point */}
        <br />
        {/* Make a table with the ItemList.js object */}
        <Row>
          <Col>
            <ReceiptsList
              receipts={this.state.receipts}
              searchQuery={this.state.searchQuery}
              groupFilter={this.state.groupFilter}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ReceiptsTab;
