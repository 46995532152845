import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Alert,
} from "reactstrap";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import PersonalCheckout from "./PersonalCheckout";
import GroupCheckout from "./GroupCheckout";
import {
  BYU_CARD_URL,
  RECEIPTS_URL,
  REFUND_URL,
  TRANSACTION_URL,
} from "../../constants";
import OutGoingReceiptsList from "../Receipts/ReceiptsList";

const CheckoutModal = ({
  resetState,
  totalPrice,
  group,
  totalLoans,
  cartID,
  loanCartID,
  transactionType,
}) => {
  const [modal, setModal] = useState(false);
  const [cardNumber, setCardNumber] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state for fetching data
  const [purchaseLoading, setPurchaseLoading] = useState(false); // Loading state for purchase
  const [purchaseSuccess, setPurchaseSuccess] = useState(null); // Purchase success state
  const [dataFetched, setDataFetched] = useState(null);

  // Toggle the modal visibility
  const toggle = () => {
    setModal(!modal);
    setCardNumber(null);
    setData([]);
    setLoading(false);
    setPurchaseSuccess(false); // Reset purchase success when closing the modal
    setDataFetched(null);
    resetState();
  };

  useEffect(() => {
    if (cardNumber !== null) {
      fetchProtectedResource();
    }
  }, [cardNumber]);

  const handleCardDataSubmit = (cardNumber) => {
    setCardNumber(cardNumber); // Update card number state
    setLoading(true); // Set loading state when card data is submitted
  };

  const fetchProtectedResource = async () => {
    setDataFetched(null);
    setLoading(true); // Set loading state before making the request
    try {
      const response = await axios.get(`${BYU_CARD_URL}${cardNumber}`);
      setData(response.data);
      console.log(response.data);
      setDataFetched(true);
    } catch (error) {
      console.error("Error fetching the protected resource:", error);
      setDataFetched(false);
    } finally {
      setLoading(false); // Stop loading once the data is fetched
    }
  };

  const generateUniqueId = () => {
    // Combine current timestamp with a random number to create a unique integer
    return (Date.now() % 1000000000) + Math.floor(Math.random() * 1000000);
  };

  const convertToIntegerString = (decimalString) => {
    // Remove the decimal point and convert the string to an integer
    return decimalString.replace(".", "");
  };

  const handlePurchase = async () => {
    // Generate unique deduplication_id and convert total price
    const deduplication_id = generateUniqueId();
    const amount = convertToIntegerString(totalPrice);
    const body = {
      type: transactionType, // Default value if not provided
      amount: amount, // Amount in cents provided by the user
      deduplication_id: deduplication_id, // Must be provided
      cart_id: cartID,
      loan_cart_id: loanCartID,
      buyer_name: data.name,
      buyer_net_id: data.net_id,
    };

    setPurchaseLoading(true); // Set purchase loading state

    try {
      // Make a POST request to the Django endpoint
      let path = `${TRANSACTION_URL}${cardNumber}`;
      if (transactionType == "refund") {
        path = `${REFUND_URL}${cardNumber}`;
      }
      // else {
      //   path = `${TRANSACTION_URL}${cardNumber}`;
      // }
      const response = await axios.post( path, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Transaction successful:", response.status);
      setPurchaseSuccess("success"); // Set purchase success state
    } catch (error) {
      console.error(
        "Error handling purchase:",
        error.response ? error.response.data : error.message,
        setPurchaseSuccess("failed")
      );
    } finally {
      setPurchaseLoading(false); // Stop loading once the purchase is complete
    }
  };

  const handleGroupPurchase = async () => {
    const body = {
      purchaserID: data.net_id,
      purchaserName: data.name,
      groupPurchaseID: group.pk,
      card_id: cartID,
      loanCartID: loanCartID,
      buyer_name: data.name,
      buyer_net_id: data.net_id,
      // OutGoingReceiptsList: data,
    };

    setPurchaseLoading(true); // Set purchase loading state

    try {
      // Make a POST request to the Django endpoint
      const response = await axios.post(`${RECEIPTS_URL}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setPurchaseSuccess("success"); // Set purchase success state
    } catch (error) {
      console.error(
        "Error handling purchase:",
        error.response ? error.response.data : error.message,
        setPurchaseSuccess("failed")
      );
    } finally {
      setPurchaseLoading(false); // Stop loading once the purchase is complete
    }
  };

  const handlePurchaseButton = () => {
    handlePurchase();
  };

  return (
    <>
      <Button
        color="success"
        className="m-1 mb-4 mt-4 float-end"
        onClick={toggle}
        style={{ minWidth: "80px" }}
      >
        {transactionType == "refund" ? "Refund" : "Checkout"}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        {!group ? (
          <PersonalCheckout
            purchaseSuccess={purchaseSuccess}
            totalLoans={totalLoans}
            handlePurchaseButton={handlePurchaseButton}
            totalPrice={totalPrice}
            loading={loading}
            purchaseLoading={purchaseLoading}
            cardNumber={cardNumber}
            data={data}
            toggle={toggle}
            handleCardDataSubmit={handleCardDataSubmit}
            dataFetched={dataFetched}
          />
        ) : (
          <GroupCheckout
            group={group}
            purchaseSuccess={purchaseSuccess}
            totalLoans={totalLoans}
            handleGroupPurchase={handleGroupPurchase}
            totalPrice={totalPrice}
            loading={loading}
            purchaseLoading={purchaseLoading}
            cardNumber={cardNumber}
            data={data}
            toggle={toggle}
            handleCardDataSubmit={handleCardDataSubmit}
            dataFetched={dataFetched}
          />
        )}
      </Modal>
    </>
  );
};

export default CheckoutModal;
