// Modified by Anthony Cardenas 8/20/24

//Following are URL PATHs
export const USER_URL = process.env.REACT_APP_USER_URL
export const ITEM_URL = process.env.REACT_APP_ITEM_URL
export const ITEM_CREATE_URL = process.env.REACT_APP_ITEM_CREATE_URL
export const EQUIPMENT_URL = process.env.REACT_APP_EQUIPMENT_URL
export const PROJECT_URL = process.env.REACT_APP_PROJECT_URL
export const PROJECT_TDP_URL = process.env.REACT_APP_PROJECT_TDP_URL
export const PROJECT_PCB_URL = process.env.REACT_APP_PROJECT_PCB_URL
export const PROJECT_LC_URL = process.env.REACT_APP_PROJECT_LC_URL
export const DOORCODE_URL = process.env.REACT_APP_DOORCODE_URL
export const OLD_CART_URL = process.env.REACT_APP_OLD_CART_URL
export const LOAN_CART_URL = process.env.REACT_APP_LOAN_CART_URL
export const LOANCART_URL = process.env.REACT_APP_LOANCART_URL
export const LOANCART_ITEM_URL = process.env.REACT_APP_LOANCART_ITEM_URL
export const LOANED_ITEMS_URL = process.env.REACT_APP_LOANED_ITEMS_URL
export const LOANS_URL = process.env.REACT_APP_LOANS_URL
export const RECEIPTS_URL = process.env.REACT_APP_RECEIPTS_URL
export const GROUPS_URL = process.env.REACT_APP_GROUPS_URL
export const CART_ITEM_URL = process.env.REACT_APP_CART_ITEM_URL
export const CART_URL = process.env.REACT_APP_CART_URL
export const BYU_CARD_URL = process.env.REACT_APP_BYU_CARD_URL
export const TRANSACTION_URL = process.env.REACT_APP_TRANSACTION_URL
export const LOGIN_VERIFICATION_URL = process.env.REACT_APP_LOGIN_VERIFICATION
export const REFUND_URL = process.env.REACT_APP_REFUND_URL

export const BASE_URL = "http://localhost:8000"
//export const BASE_URL = "elcparts.byu.edu"
export const CSRF_URL = process.env.REACT_APP_CSRF_URL

//Below are local storage items
export const MAIN_CART_ID = -1

export const ACCESS_TOKEN = "access"
export const REFRESH_TOKEN = "refresh"
