import React from "react"

const SAML = () => {
  const handleLogin = () => {
    // Redirects to Django's SAML login endpoint
    window.location.href = process.env.REACT_APP_SAML_LOGIN
  }

  const handleViewMetadata = () => {
    window.location.href = process.env.REACT_APP_SAML_METADATA
  }
  return (
    <div>
      This tab is for SAML Testing Please do not touch anything on this tab
      unless you are a web dev
      <button onClick={handleLogin}>Go to login screen</button>
      <button onClick={handleViewMetadata}>View Site Metadata</button>
    </div>
  )
}

export default SAML
