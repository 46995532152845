//Created by Logan Cropper 6/5/2024

import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import NewGroupForm from "./NewGroupForm";

class NewGroupModal extends Component {
    state = {
        modal: false
    }

    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }))
    };

    render(){
        const create = this.props.create;

        //Button will edit unless create mdoe is specified
        var title = "Editing Group";
        var button = <Button
                        color="primary"
                        onClick={this.toggle}
                    >
                        Edit
                    </Button>
        var title = "";
        if(create){
            title = "Creating New Group";
            var button = (
                <Button
                    color="primary"
                    className="mb-4 mt-4 mr-4"
                    onClick={this.toggle}
                >
                    Create Group
                </Button>
            );
        }else{
            title = "Editing Group";
            button = (
                <Button
                    color="primary"
                    className="mb-0 mt-0 mr-0"
                    onClick={this.toggle}
                >
                    Edit
                </Button>
            );
        }

        return (
            <Fragment>
                {button}
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
                    <ModalBody>
                        <NewGroupForm
                            resetState={this.props.resetState}
                            toggle={this.toggle}
                            group={this.props.group}
                        />
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default NewGroupModal;