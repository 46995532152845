import React, { useState } from "react"
import {
  Button,
  Modal,
  Col,
  Row,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import axios from "axios"

const InProgressModal = ({
  project,
  typeOfRequest,
  resetState,
  projectType,
}) => {
  const [modal, setModal] = useState(false)
  const [price, setPrice] = useState(0)

  const toggle = () => {
    setModal(!modal)
  }

  const completeJob = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_PROJECT_URL}${projectType}/update_job/${project.pk}`,
        { status_type: "CP", price: price }, // Body of the request
        {
          headers: {
            "Content-Type": "application/json", // Ensure you're sending JSON data
          },
        }
      )
      toggle() // Close the modal
      resetState() // Refresh the list of items
    } catch (error) {
      console.error("Error starting job:", error)
    }
  }

  const onChange = (e) => {
    e.preventDefault()
    setPrice(e.target.value)
  }
  return (
    <div>
      <Button onClick={toggle} className="m-4" color="success">
        Complete
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Complete project for {project.customerName}
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label>Price:</Label>
            <Input
              className="w-25"
              type="number"
              name="price"
              onChange={onChange}
              value={price}
            />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={completeJob}>
            Start Job
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default InProgressModal
