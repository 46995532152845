import React, { useState, useMemo, useEffect } from "react"

import { getCoreRowModel, useReactTable } from "@tanstack/react-table"
import Fuse from "fuse.js"

import TableSearch from "./TableSearch"
import TableHead from "./TableHead"
import TableBody from "./TableBody"
import TableFooter from "./TableFooter"
import TablePages from "./TablePages"

import styles from "./table.css"

const Table = ({ parentData, columns, paginate, searchable, fixedLayout }) => {
  const [data, setData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(paginate ? 10 : 10000)

  useEffect(() => {
    setData(Array.isArray(parentData) ? parentData : [])
  }, [parentData])

  //This method filters the table based on the search box
  //If
  const filteredData = useMemo(() => {
    if (searchable && data.length > 0) {
      const fuse = new Fuse(data, {
        keys: Object.keys(data[0]), // Adjust this if you want to filter on specific fields
        threshold: 0.5,
      })
      if (paginate) {
        setPageIndex(0)
      }
      return searchTerm ? fuse.search(searchTerm).map(({ item }) => item) : data
    }
    return data
  }, [searchTerm, data])

  const paginatedData =
    paginate && filteredData.length > 0
      ? filteredData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
      : filteredData
  const pageCount = paginate ? Math.ceil(filteredData.length / pageSize) : 0

  //This creates the table based on the data and types of columns we will use
  const table = useReactTable({
    data: paginatedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: paginate,
    pageCount,
  })

  return (
    <div className="p-2">
      {searchable && (
        <TableSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      )}
      <table
        className={styles.table}
        style={{ tableLayout: fixedLayout ? "fixed" : "auto" }}
      >
        <TableHead table={table} />
        <TableBody table={table} paginate={paginate} />
        <TableFooter table={table} />
      </table>
      <div className="h-4">
        {paginate && (
          <TablePages
            setPageIndex={setPageIndex}
            pageIndex={pageIndex}
            pageCount={pageCount}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </div>
      <div className="h-4" />
    </div>
  )
}

export default Table
