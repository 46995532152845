// Created 5/29/2024 - Logan Cropper

import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import ReceiptsPDF from "./ReceiptsPDF";
import NewReceiptPDF from "./ReceiptsPDF";

//This component is set up to be used in 2 ways:
//  -Standalone as a button, to be used in the receipts history list
//  -To be triggered by the checkout form
//The component is set up to receive props so that a parent component can control the modal. If no props are received, it will function on its own.
class ReceiptsModal extends Component {
    state = {
        modal: false
    };

    toggle = ('toggle' in this.props) ? this.props.toggle : () => { //Use parent toggle function if available, otherwise use internal toggle
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    //Gets total price of the purchase
    getPriceTotal = (purchase) => {
        var totalPrice = 0;
        for(var i = 0; i < purchase.OutgoingReceiptsExtendeds.length; i++){
            totalPrice += purchase.OutgoingReceiptsExtendeds[i].price * purchase.OutgoingReceiptsExtendeds[i].quantity;
        }
        return totalPrice;
    }

    render() {
        //Render button only if specified in props
        var button = (this.props.showButton ?
            <Button
                color="primary"
                className="m-1 float-end"
                onClick={this.toggle}
            >
                View
            </Button>
            :
            null
        ); 

        return (
            <Fragment>
                {button}
                {/* Show modal according to parent prop if available, otherwise use internal state */}
                <Modal isOpen={('showModal' in this.props) ? this.props.showModal : this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Purchase Receipt</ModalHeader>
                    <ModalBody>
                        {/* Actual PDF generation component */}
                        <ReceiptsPDF
                            purchase = {this.props.purchase}
                            getPriceTotal = {this.getPriceTotal}
                            getGroupName = {this.props.getGroupName}
                        />
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default ReceiptsModal;