//Updated to modern React 07/26/2024

import React, { useState } from "react"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import axios from "axios" // Axios is used for HTTP interactions. In this file, POST and PUT.

import api from "../../api"

import { MAIN_CART_ID, CART_ITEM_URL } from "../../constants"

const QuantityForm = ({ item, resetState, toggle }) => {
  const [quantityToOrder, setQuantityToOrder] = useState(1)

  // Reflect user interaction with state changes
  const onChange = (e) => {
    setQuantityToOrder(e.target.value)
  }

  // Create a new entry for a cartItem that will be displayed on the cart page.
  // - Get the data corresponding to the item that we are adding to the cart
  // - Post to Cart with the name corresponding to pk item, price, and chosen quantity.

  const createItem = async (e) => {
    // e.preventDefault() // Prevent form submission
    const curr_cart = localStorage.getItem(MAIN_CART_ID)

    const payLoad = {
      cart: curr_cart,
      item: item.pk,
      quantity: quantityToOrder,
    }

    console.log(payLoad)

    try {
      const response = await axios.post(CART_ITEM_URL, payLoad)
      console.log(response)
    } catch (error) {
      console.error("Error during test request:", error)
    }
  }

  return (
    <Form onSubmit={createItem}>
      <FormGroup>
        <Label for="quantityToOrder">Quantity:</Label>
        <Input
          type="number"
          name="quantityToOrder"
          onChange={onChange}
          value={quantityToOrder}
        />
      </FormGroup>

      <Button
        onClick={() => {
          createItem()
          toggle()
        }}
      >
        Submit
      </Button>
    </Form>
  )
}

export default QuantityForm
