//Updated to modern React 07/26/2024

import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Col,
  Row,
} from "reactstrap"

//import axios from "axios" // Axios is used for HTTP interactions. In this file, POST and PUT.
import api from "../../api"
import axios from "axios"

import "./items.css"

import { ITEM_URL, ITEM_CREATE_URL } from "../../constants"

const ItemForm = ({ item, resetState, toggle }) => {
  const [formData, setFormData] = useState({
    pk: 0,
    name: "",
    quantity: 0,
    price: 0,
    description: "",
    location: "",
    newImageSelected: false,
    image: null,
    errorMessage: "",
    threshold: 0,
    maxQuantity: 0,
    dataSheet: "",
    loanable: false,
  })

  useEffect(() => {
    if (item) {
      const {
        pk,
        name,
        quantity,
        price,
        description,
        location,
        image,
        loanable,
        threshold,
        maxQuantity,
        dataSheet,
      } = item
      setFormData({
        pk,
        name,
        quantity,
        price,
        description,
        location,
        image,
        loanable,
        newImageSelected: false,
        threshold,
        maxQuantity,
        dataSheet,
      })
    }
  }, [item])

  const onChange = (e) => {
    const { name, type, value, files, checked } = e.target
    if (name === "image") {
      setFormData((prevState) => ({
        ...prevState,
        image: files[0],
        newImageSelected: true,
      }))
    } else if (name === "loanable") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }))
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const {
      name,
      quantity,
      price,
      location,
      loanable,
      pk,
      newImageSelected,
      image,
      threshold,
      dataSheet,
      maxQuantity,
    } = formData

    // Validate input fields before making the request
    if (!name || !quantity || !price || !location) {
      setFormData((prevState) => ({
        ...prevState,
        errorMessage: "Please fill out all fields.",
      }))
      return
    }

    const data = new FormData()
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && (key !== "image" || newImageSelected)) {
        data.append(key, formData[key])
      }
    })

    console.log(data)

    try {
      if (pk) {
        await axios.put(`${ITEM_URL}${pk}`, data)
      } else {
        await axios.post(ITEM_CREATE_URL, data) // Posting new item
      }
      resetState()
      toggle()
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data

        // Check if the dataSheet field has an error
        if (
          errorData.dataSheet &&
          errorData.dataSheet.includes("Enter a valid URL.")
        ) {
          console.log("Invalid URL provided.")
        }

        // Update the formData state with the error message
        setFormData((prevState) => ({
          ...prevState,
          errorMessage: `Error ${pk ? "editing" : "creating"} item. ${
            errorData.dataSheet || "Please try again."
          }`,
        }))
      } else {
        // Handle unexpected errors
        setFormData((prevState) => ({
          ...prevState,
          errorMessage: "An unexpected error occurred. Please try again.",
        }))
      }
    }
  } // <-- Missing closing bracket added here

  const defaultIfEmpty = (value) => (value === "" ? "" : value)

  return (
    <Form onSubmit={handleSubmit}>
      {formData.errorMessage && (
        <Alert color="danger">{formData.errorMessage}</Alert>
      )}
      <Row>
        <Col>
          <FormGroup>
            <Label for="name">
              Name:<span className="required">*</span>
            </Label>
            <Input
              type="text"
              name="name"
              onChange={onChange}
              value={defaultIfEmpty(formData.name)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="location">
              Location:<span className="required">*</span>
            </Label>
            <Input
              type="text"
              name="location"
              onChange={onChange}
              value={defaultIfEmpty(formData.location)}
            />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="description">Description:</Label>
        <Input
          type="text"
          name="description"
          onChange={onChange}
          value={defaultIfEmpty(formData.description)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="image">Image:</Label>
        <Input type="file" name="image" onChange={onChange} />
      </FormGroup>
      <FormGroup>
        <Label for="dataSheet">Datasheet:</Label>
        <Input
          type="text"
          name="dataSheet"
          onChange={onChange}
          value={formData.dataSheet}
        />
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <Label for="quantity">
              Quantity:<span className="required">*</span>
            </Label>
            <Input
              type="number"
              name="quantity"
              onChange={onChange}
              value={formData.quantity}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="price">
              Price:<span className="required">*</span>
            </Label>
            <Input
              type="number"
              name="price"
              onChange={onChange}
              value={formData.price}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <Label for="threshold">Threshold:</Label>
            <Input
              type="number"
              name="threshold"
              onChange={onChange}
              value={formData.threshold}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="maxQuantity">Max Quantity:</Label>
            <Input
              type="number"
              name="maxQuantity"
              onChange={onChange}
              value={formData.maxQuantity}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label for="loanable">Loanable:</Label>
        <Input
          type="checkbox"
          name="loanable"
          onChange={onChange}
          checked={formData.loanable}
        />
      </FormGroup>
      <Button>Send</Button>
    </Form>
  )
}

export default ItemForm
